/*
 * Servizio che gestisce i vari redirect
*/

module RedirectManager {
	let app = angular.module("app");

	app.service("RedirectManager", function ($state: any, $mdComponentRegistry: any, $window: any, GlobalApplicationData: any, $stateParams: any, $mdSidenav: any, CustomersinvitationsApplicationData: any, $sessionStorage: any) {
		// Metodo che ritorna l'URL della Formazione Reti
		this.getCustomersInvitationsUrl = () => {
			let url = {
				baseUrl: '',
				protocol: '',
				secondLevelDomain: '',
				thirdLevelDomain: ''
			}

			if ($window && $window.location) {
				// Calcolo il dominio di appartenenza per chiamare i servizi Rest
				let port = window.location.protocol === "http:" ? 80 : 443;
				url.protocol = window.location.protocol === "http:" ? "http" : "https";
				//     url.baseUrl = window.location.protocol + "//" + window.location.hostname + ":" + port + "/";
				url.baseUrl = window.location.protocol + "//" + window.location.hostname + "/";
				// Recupero il nome del dominio per caricare l'iFrame di cross-domain local storage
				let firstDot = 0;
				let lastDot = window.location.hostname.lastIndexOf(".");
				if (lastDot != -1) {
					firstDot = window.location.hostname.lastIndexOf(".", lastDot - 1);
				}
				if (firstDot == -1) {
					firstDot = 0;
				}
				url.secondLevelDomain = window.location.hostname.substring(firstDot, window.location.hostname.length);
				if (url.secondLevelDomain.charAt(0) == ".") {
					url.secondLevelDomain = url.secondLevelDomain.substring(1, window.location.hostname.length);
				}
				// Per debug verifico se il primo pezzo del dominio è "localhost o atf", allora tengo questo invece del "www"
				if (window.location.hostname.indexOf("localhosti") == 0) {
					url.thirdLevelDomain = "localhosti.";
				}
				else if (window.location.hostname.indexOf("atf") == 0 && firstDot > 0 && firstDot <= 5) {
					url.thirdLevelDomain = "atfi.";
				}
				else if (window.location.hostname.indexOf("test") == 0 && firstDot > 0) {
					url.thirdLevelDomain = "testmeetingclienti.";
				}
				else {
					url.thirdLevelDomain = "meetingclienti.";
				}
			};

			return url;
		};

		// Metodo che ritorna l'URL del login via SSO assieme all'url di a cui tornare
		this.getCustomersInvitationsSsoUrl = () => {
			let tmpUrl = this.getCustomersInvitationsUrl();
			let urls: any = {};
			urls.ssoRedirectPage = tmpUrl.baseUrl + "sso/login";
			urls.ssoRedirectLogoutPage = tmpUrl.baseUrl + "sso/logout";
			urls.ssoReturnPage = tmpUrl.baseUrl + "#/app/localLogin";
			return urls;
		};

		// Se la sidenav è aperta, la chiuso
		this.closeSidenav = () => {
			let sidenav: any = $mdComponentRegistry.get('sidenavMenu');
			if (sidenav && $mdSidenav('sidenavMenu') && $mdSidenav('sidenavMenu').isOpen()) {
				$mdSidenav('sidenavMenu').toggle();
			}
		}

		// Porta alla home page
		this.goToHome = () => {
			this.closeSidenav();
			$state.go('app.customersinvitationsApp.home');
		}

		// Porta alla lista dei format liberi
		this.goToFreeFormatList = () => {
			this.closeSidenav();
			$state.go('app.customersinvitationsApp.freeFormatList');
		}

		// Porta alla pagina per la gestione delle immagini dei format liberi
		this.goToFreeFormatImages = () => {
			this.closeSidenav();
			$state.go('app.customersinvitationsApp.freeFormatImages');
		}

		// Porta alla home page
		this.goToPreviewLandingPage = (editionObj: any) => {
			if (editionObj) {
				let url = this.getCustomersInvitationsUrl();
				let completeurl = url && url.baseUrl + "#/app/access/landingPage/" + editionObj.courseId + "/" + editionObj.courseDateId + "/" + GlobalApplicationData.jwtPayload.user.userId
					+ "/" + GlobalApplicationData.jwtPayload.user.userTenant.tenant + "/" + GlobalApplicationData.jwtPayload.user.userOptions.langCode;
				window.open(completeurl, "_blank");
			}
		}

		// Porta alla pagina con le campagne
		this.goToDirectionals = () => {
			this.closeSidenav();
			$state.go('app.customersinvitationsApp.directionals');
		}

		// Porta alla pagina con i format
		this.goToFormat = () => {
			this.closeSidenav();
			$state.go('app.customersinvitationsApp.format');
		}

		// Porta alla pagina per la creazione di un direzionale
		this.goToCreateDirectional = () => {
			this.closeSidenav();
			$state.go('app.customersinvitationsApp.newDirectional');
		}

		// Porta alla pagina per la modifica di un modulo
		this.goToEditModule = (type: string, courseId: string) => {
			if (type === CustomersinvitationsApplicationData.constants.COURSE_TYPE_DIRECTIONAL) {
				$state.go('app.customersinvitationsApp.editDirectional', { courseId: courseId });
			} else if (type === CustomersinvitationsApplicationData.constants.COURSE_TYPE_FORMAT) {
				$state.go('app.customersinvitationsApp.editFormat', { courseId: courseId });
			}
		}

		// Porta alla pagina per la modifica di un format libero
		this.goToEditFreeFormat = (courseId: string) => {
			$state.go('app.customersinvitationsApp.editFreeFormat', { courseId: courseId });
		}

		// Porta alla pagina per la modifica di un'edizione
		this.goToEditEdition = (type: string, courseId: string, courseDateId: string, joinId?: string, isOnlineOnDemandMeeting?: boolean) => {
			if (type === CustomersinvitationsApplicationData.constants.COURSE_DATE_TYPE_DIRECTIONAL) {
				$state.go('app.customersinvitationsApp.editDirectionalMeeting', { courseId: courseId, courseDateId: courseDateId, joinId: joinId });
			} else {
				// Se non è un direzionale, è un Format
				// Le due route seguenti sono identiche, cambia solo l'url, ed è stato fatto affinché venisse attivato il puslante corretto nell'header (in caso "Meeting", nell'altro "Meeting on demand online")
				if (isOnlineOnDemandMeeting) {
					$state.go('app.customersinvitationsApp.editFormatMeetingOnlineOnDemand', { courseId: courseId, courseDateId: courseDateId, joinId: joinId });
				} else {
					$state.go('app.customersinvitationsApp.editFormatMeeting', { courseId: courseId, courseDateId: courseDateId, joinId: joinId });
				}
			}
		}

		// Porta alla pagina con la lista di on demand online
		this.goToOnlineOnDemandMeetings = () => {
			this.closeSidenav();
			$state.go('app.customersinvitationsApp.onlineOnDemandMeeting');
		}

		// Porta alla pagina con la lista di meeting
		this.goToMeeting = () => {
			this.closeSidenav();
			if ($sessionStorage.isTraining || $sessionStorage.isSupervisor) {
				this.goToFormat();
			} else {
				$state.go('app.customersinvitationsApp.meeting');
			}
		}

		// Porta alla pagina con la lista dei materiali dei moduli
		this.goToMaterials = () => {
			this.closeSidenav();
			$state.go('app.customersinvitationsApp.materials');
		}

		// Porta alla pagina dei report
		this.goToReport = () => {
			this.closeSidenav();
			$state.go('app.customersinvitationsApp.report');
		}

		// Porta alla pagina per la creazione di un format
		this.goToCreateFormat = () => {
			this.closeSidenav();
			$state.go('app.customersinvitationsApp.newFormat');
		}

		// Porta alla pagina per la creazione di un format libero
		this.goToCreateFreeFormat = () => {
			this.closeSidenav();
			$state.go('app.customersinvitationsApp.newFreeFormat');
		}

		// Porta alla pagina per la creazione di un meeting di un direzionale
		this.goToCreateDirectionalMeeting = () => {
			$state.go('app.customersinvitationsApp.newDirectionalMeeting');
		}

		// Porta alla pagina con la lista di meeting riservati per una specifica Campagna
		this.goToReservedMeetingListOfDirectional = (courseId: string) => {
			$state.go('app.customersinvitationsApp.reservedMeetingListOfDirectional', { courseId: courseId });
		}

		// Porta alla pagina con la lista di meeting creati di uno specifico Modulo
		this.goToCreatedMeetingsOfModule = (isDirectional: boolean, courseId: string, isFreeFormat?: boolean) => {
			if (isDirectional) {
				$state.go('app.customersinvitationsApp.createdMeetingListOfDirectional', { courseId: courseId });
			} else {
				$state.go('app.customersinvitationsApp.meetingListOfFormat', { courseId: courseId, isFreeFormat: isFreeFormat });
			}
		}

		// Porta alla pagina per la creazione di un meeting di un format
		this.goToCreateFormatMeeting = () => {
			$state.go('app.customersinvitationsApp.newFormatMeeting');
		}

		// Porta alla pagina con la lista di docenti
		this.goToTeachers = () => {
			this.closeSidenav();
			$state.go('app.customersinvitationsApp.teachers');
		}

		// Porta alla pagina con la lista delle richieste dei relatori
		this.goToTeacherRequests = () => {
			this.closeSidenav();
			$state.go('app.customersinvitationsApp.trainerRequests');
		}

		// Porta alla pagina con la lista di Template
		this.goToTemplates = () => {
			this.closeSidenav();
			$state.go('app.customersinvitationsApp.templates');
		}

		// Porta alla pagina per la creazione di un nuovo template
		this.goToCreateNewTemplate = () => {
			this.closeSidenav();
			$state.go("app.customersinvitationsApp.createTemplate");
		}

		// Porta alla pagina per l'edit di un template
		this.goToEditTemplate = (selectedTemplate: any) => {
			$state.go("app.customersinvitationsApp.editTemplate", { textTemplateId: selectedTemplate.textTemplateId });
		}
	});
}